<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                to="/events/register"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Evento</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                to="/events/register"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="members"
          :search="search"
        >
          <template v-slot:[`item.startDate`]="{ item }">
            <span> {{ dateFormat(item.startDate) }}</span>
          </template>
          <template v-slot:[`item.unitPrice`]="{ item }">
            <span> {{ formatPrice(item.unitPrice) }}</span>
          </template>
          <template v-slot:[`item.endDate`]="{ item }">
            <span> {{ dateFormat(item.endDate) }}</span>
          </template>
          <template v-slot:[`item.participants`]="{ item }">
            <v-btn icon :to="`/events/${item.id}/participants`">
              <v-icon small class="mr-2">mdi-account-supervisor</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon :to="`/events/${item.id}/edit`">
              <v-icon small class="mr-2">mdi-pencil</v-icon>
            </v-btn>
            <v-icon
              v-if="verifyDeletePermission()"
              small
              class="mr-2"
              @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";
import moment from "moment";

export default Vue.extend({
  name: "Eventos",
  components: { InternalMenu, PageHeader },
  data: () => ({
    title: "Eventos",
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Eventos",
        disabled: true,
        href: "/events",
      },
    ],
    deleteDialog: false,
    search: "",
    members: [],
    loading: false,
    loadingAction: false,
    event: {
      id: null,
      unitPrice: 0.0,
    },
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    headers: [
      {
        text: "Tipo",
        align: "start",
        value: "type.title",
        filtering: true,
      },
      {
        text: "Título",
        align: "start",
        value: "title",
        filtering: true,
      },
      {
        text: "Início",
        align: "start",
        value: "startDate",
        filtering: true,
      },
      {
        text: "Fim",
        align: "start",
        value: "endDate",
        filtering: true,
      },
      {
        text: "Preço",
        align: "start",
        value: "unitPrice",
        filtering: true,
      },
      { text: "Participantes", value: "participants" },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    dateFormat(value) {
      if (!value || value === "") return "-";
      return moment(value).format("DD/MM/YYYY");
    },
    formatPrice(value) {
      if (!value || value <= 0) return "Grátis";
      let val = (value / 1).toFixed(2).replace(".", ",");
      return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    verifyDeletePermission() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.admin) return true;
      if (
        user &&
        user?.group?.permissions &&
        user?.group?.permissions?.map((a)=> a.id)?.includes(
          "digitech_plus.immobile.members.delete_one"
        )
      ) {
        return true;
      }
      return false;
    },
    deleteItem() {
      http.delete(`promise/events/${this.id}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.id = null;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    getItems() {
      this.loading = true;
      http.get("promise/events").then(
        (data) => {
          this.members = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
  },
  mounted() {
    this.getItems();
  },
});
</script>
